import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6b102d26 = () => interopDefault(import('../pages/company-homepage.vue' /* webpackChunkName: "pages/company-homepage" */))
const _30dcb0b7 = () => interopDefault(import('../pages/download.vue' /* webpackChunkName: "pages/download" */))
const _3652f334 = () => interopDefault(import('../pages/download_hireyapp.vue' /* webpackChunkName: "pages/download_hireyapp" */))
const _3348dacb = () => interopDefault(import('../pages/find-jobs.vue' /* webpackChunkName: "pages/find-jobs" */))
const _4b32dd97 = () => interopDefault(import('../pages/fraud_prevention_guide.vue' /* webpackChunkName: "pages/fraud_prevention_guide" */))
const _97be0bf0 = () => interopDefault(import('../pages/hirey-app-reviews.vue' /* webpackChunkName: "pages/hirey-app-reviews" */))
const _1ffb2815 = () => interopDefault(import('../pages/hirey-recommend.vue' /* webpackChunkName: "pages/hirey-recommend" */))
const _08e20034 = () => interopDefault(import('../pages/hirey-reviews.vue' /* webpackChunkName: "pages/hirey-reviews" */))
const _c2ae7724 = () => interopDefault(import('../pages/hireydownload.vue' /* webpackChunkName: "pages/hireydownload" */))
const _4f6868f9 = () => interopDefault(import('../pages/hireystatement.vue' /* webpackChunkName: "pages/hireystatement" */))
const _5395951d = () => interopDefault(import('../pages/homepage.vue' /* webpackChunkName: "pages/homepage" */))
const _0ad88b74 = () => interopDefault(import('../pages/jobseeker/index.vue' /* webpackChunkName: "pages/jobseeker/index" */))
const _dc768a68 = () => interopDefault(import('../pages/recruiter.vue' /* webpackChunkName: "pages/recruiter" */))
const _44f2d640 = () => interopDefault(import('../pages/webhirey.vue' /* webpackChunkName: "pages/webhirey" */))
const _15d4340c = () => interopDefault(import('../pages/webhireyrecommend.vue' /* webpackChunkName: "pages/webhireyrecommend" */))
const _14d27367 = () => interopDefault(import('../pages/jobseeker/search/index.vue' /* webpackChunkName: "pages/jobseeker/search/index" */))
const _684d394b = () => interopDefault(import('../pages/blog/list/_pageNum.vue' /* webpackChunkName: "pages/blog/list/_pageNum" */))
const _d1bc1ef8 = () => interopDefault(import('../pages/jobseeker/search/_query.vue' /* webpackChunkName: "pages/jobseeker/search/_query" */))
const _5cd0a2fa = () => interopDefault(import('../pages/apply/_id.vue' /* webpackChunkName: "pages/apply/_id" */))
const _9d87c908 = () => interopDefault(import('../pages/blog/_id.vue' /* webpackChunkName: "pages/blog/_id" */))
const _6e068aee = () => interopDefault(import('../pages/job/_id.vue' /* webpackChunkName: "pages/job/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/company-homepage",
    component: _6b102d26,
    meta: {"name":"Homepage"},
    name: "company-homepage"
  }, {
    path: "/download",
    component: _30dcb0b7,
    meta: {"name":"WebB"},
    name: "download"
  }, {
    path: "/download_hireyapp",
    component: _3652f334,
    meta: {},
    name: "download_hireyapp"
  }, {
    path: "/find-jobs",
    component: _3348dacb,
    meta: {},
    name: "find-jobs"
  }, {
    path: "/fraud_prevention_guide",
    component: _4b32dd97,
    meta: {},
    name: "fraud_prevention_guide"
  }, {
    path: "/hirey-app-reviews",
    component: _97be0bf0,
    meta: {"name":"reviews","props":{}},
    name: "hirey-app-reviews"
  }, {
    path: "/hirey-recommend",
    component: _1ffb2815,
    meta: {},
    name: "hirey-recommend"
  }, {
    path: "/hirey-reviews",
    component: _08e20034,
    meta: {"name":"reviews","props":{}},
    name: "hirey-reviews"
  }, {
    path: "/hireydownload",
    component: _c2ae7724,
    meta: {},
    name: "hireydownload"
  }, {
    path: "/hireystatement",
    component: _4f6868f9,
    meta: {},
    name: "hireystatement"
  }, {
    path: "/homepage",
    component: _5395951d,
    meta: {},
    name: "homepage"
  }, {
    path: "/jobseeker",
    component: _0ad88b74,
    meta: {},
    name: "jobseeker"
  }, {
    path: "/recruiter",
    component: _dc768a68,
    meta: {},
    name: "recruiter"
  }, {
    path: "/webhirey",
    component: _44f2d640,
    meta: {"name":"webhireyH5","fetchOnServer":true},
    name: "webhirey"
  }, {
    path: "/webhireyrecommend",
    component: _15d4340c,
    meta: {"name":"webhireyH5","fetchOnServer":true},
    name: "webhireyrecommend"
  }, {
    path: "/jobseeker/search",
    component: _14d27367,
    meta: {"name":"index"},
    name: "jobseeker-search"
  }, {
    path: "/blog/list/:pageNum?",
    component: _684d394b,
    meta: {},
    name: "blog-list-pageNum"
  }, {
    path: "/jobseeker/search/:query",
    component: _d1bc1ef8,
    meta: {"name":"query"},
    name: "jobseeker-search-query"
  }, {
    path: "/apply/:id?",
    component: _5cd0a2fa,
    meta: {},
    name: "apply-id"
  }, {
    path: "/blog/:id?",
    component: _9d87c908,
    meta: {},
    name: "blog-id"
  }, {
    path: "/job/:id?",
    component: _6e068aee,
    meta: {},
    name: "job-id"
  }, {
    path: "/",
    component: _0ad88b74,
    meta: {},
    name: "recruiter_1"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
